<template>
  <div :class="containerClass">
    <img
      v-if="profileImg"
      :src="resolve_porfile_img(dimensions, profileImg, profile_id)"
      alt="profile-img"
      :class="avatarClass"
      :title="title"
      :style="imageStyle"
    />

    <img
      v-else
      :src="
        resolve_porfile_img(dimensions, `ananimous_${gender}.jpg`, 'ananimous')
      "
      alt="profile-img"
      :title="title"
      :class="avatarClass"
      :style="imageStyle"
    />
    <slot />
  </div>
</template>

<script>
import profileImagesService from "@/API/services/profile.images.service";
export default {
  name: "BaseAvatar",
  props: {
    profileImg: {
      type: String,
      default: null,
    },
    profile_id: {
      type: Number,
      default: null,
    },
    dimensions: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "user image",
    },
    avatarClass: {
      type: String,
      default: "course__teacher-thumb-3",
    },
    containerClass: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
      default: "male",
    },
    imageStyle: {
      type: Object,
      default: null,
    },
  },
  methods: {
    /**
     * get profile picture or cover.
     * @param  {String} size,
     * @param {String} name
     * @param {Int, String} profile_id
     * @return image url
     */
    resolve_porfile_img(size, imageName, profile_id) {
      return profileImagesService.resolve_porfile_img(
        size,
        imageName,
        profile_id
      );
    },
  },
};
</script>
