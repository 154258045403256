<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  async mounted() {
    await this.$store.commit('initialiseStore')
  },
}
</script>


